import type { Chat } from '@zel-labs/firebase-chat'
import { ChatsContextProvider, typeConverter, useFirebaseAnalytics, useFirebaseAuth } from '@zel-labs/firebase-chat'
import type { PropsWithChildren } from 'react'
import { useCallback, useMemo } from 'react'
import type { DocumentReference } from 'firebase/firestore'
import { addDoc, collection, orderBy, query, serverTimestamp, where } from 'firebase/firestore'
import type { Meeting } from '@zel-labs/shared/model'
import { useCoachingContext } from '../coaching'
import { useMeetingContext } from '../meeting'
import { trackEvent } from '@zel-labs/shared/firebase'

export interface MeetingFocusedChat extends Chat {
  meetingRef: DocumentReference<Meeting>;
  agent_id: 'meeting-focused';
}

export function MeetingCopilotContextProvider({ children }: PropsWithChildren) {
  const { analytics } = useFirebaseAnalytics()
  const { user } = useFirebaseAuth()
  const { coaching, role } = useCoachingContext()
  const { meeting, data } = useMeetingContext()

  const chatsCollection = useMemo(
    () =>
      coaching != null
        ? collection(coaching.ref, 'chats').withConverter(typeConverter<Chat>())
        : null,
    [coaching]
  )

  const chatsQuery = useMemo(
    () => {
      if (chatsCollection == null || meeting == null || user == null || role == null) {
        return null
      }

      const userFilter = role !== 'admin'
        ? query(chatsCollection, where('created_by', '==', user?.uid))
        : chatsCollection

      return query(userFilter,
        where('agent_id', '==', 'meeting-focused'),
        where('meetingRef', '==', meeting.ref),
        orderBy('updated_at', 'desc')
      ).withConverter(typeConverter<MeetingFocusedChat>())
    },
    [chatsCollection, meeting, role, user]
  )

  const createChat = useCallback(
    () => {
      if (meeting == null || user == null || chatsCollection == null || data?.transcript == null) {
        throw new Error('Chat not initialized')
      }

      const docRef = addDoc(chatsCollection.withConverter(typeConverter<MeetingFocusedChat>()), {
        agent_id: 'meeting-focused',
        meetingRef: meeting.ref,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        title: 'Meeting Chat',
        created_by: user.uid
      })
      docRef.then((docRef) => {
        trackEvent(analytics, 'copilot_chat_created')
      })
      return docRef
    }, [chatsCollection, data?.transcript, meeting, user, analytics]
  )

  if (chatsQuery == null) {
    return null
  }

  return <ChatsContextProvider createChat={createChat} chatsQuery={chatsQuery}>{children}</ChatsContextProvider>
}

import { Breadcrumbs, HomeBreadcrumb, PageContainer } from '@zel-labs/shared/mui'
import { CoachingBreadcrumb, CoachingContextProvider, CoachingDetails, Coachings, useCoachingContext, useCoachings } from './coaching'
import React, { useEffect, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { FirstCoachingFab, NewCoachingFab } from './components'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import type { Coaching } from '@zel-labs/shared/model'
import { Trans, useTranslation } from 'react-i18next'
import { ProtectedRoute, useUserContext } from '@zel-labs/auth'
import { trackEvent } from '@zel-labs/shared/firebase'
import { useFirebaseAnalytics } from '@zel-labs/firebase-chat'

export function DashboardPage() {
  return <ProtectedRoute>
    <DashboardPageContent />
  </ProtectedRoute>
}

export function DashboardPageContent() {
  const { analytics } = useFirebaseAnalytics()
  const coachees = useCoachings('coach')
  const coaches = useCoachings('coachee')
  const { roles } = useUserContext()

  const isCoacheeOnly = useMemo(
    () => {
      if (coachees != null && coaches != null) {
        return coachees.length === 0 && coaches.length !== 0 && !roles.includes('admin')
      }
    },
    [coachees, coaches, roles]
  )

  useEffect(() => {
    trackEvent(analytics, 'dashboard_opened')
  }, [analytics])

  if (coaches == null && coachees == null) {
    return null
  }

  if (isCoacheeOnly && coaches?.length === 1) {
    return <CoachingContextProvider coaching={coaches[0]} role="coachee">
      <CoacheeDashboard />
    </CoachingContextProvider>
  }

  return <CoachDashboard coachees={coachees} coaches={coaches} />
}

interface CoachDashboardProps {
  coachees: QueryDocumentSnapshot<Coaching>[] | null | undefined;
  coaches: QueryDocumentSnapshot<Coaching>[] | null | undefined;
}

function CoachDashboard({ coachees, coaches }: CoachDashboardProps) {
  const { t } = useTranslation()

  if (coachees?.length === 0 && coaches?.length === 0) {
    return <PageContainer title={t('title')}>
      <Stack direction="column" justifyContent="center" alignItems="center" flexGrow={1}>
        <Typography variant="h4" mb={2}><Trans i18nKey="dashboard.coachings.no-coachings" /></Typography>
        <FirstCoachingFab />
      </Stack>
    </PageContainer>
  }

  return <PageContainer title={t('title')}>
    {coaches != null && coaches.length > 0
      && <Coachings role="coachee" title={t('dashboard.coachings.coaches-header')} coachings={coaches} />}
    {coachees != null && coachees.length > 0
      && < Coachings role="coach" title={t('dashboard.coachings.coachees-header')} coachings={coachees} />
    }
    <NewCoachingFab />
  </PageContainer>
}


function CoacheeDashboard() {
  const { coaching, role, coachingTitle } = useCoachingContext()
  const { t } = useTranslation()

  return <PageContainer title={coachingTitle ?? t('title')}>
    <CoachingContextProvider coaching={coaching} role={role}>
      <Breadcrumbs separator="›">
        <HomeBreadcrumb />
        <CoachingBreadcrumb />
      </Breadcrumbs>
      <CoachingDetails />
    </CoachingContextProvider>
  </PageContainer>
}

import {useFirestoreSnapshot} from '@zel-labs/firebase-chat'
import type {DocumentReference} from 'firebase/firestore'
import {useMemo} from 'react'
import * as React from 'react'
import type {InterviewSummary, PartialLQAssessmentRequest, QualityAssessment} from './types'
import {qualities} from './types'
import {Box, Skeleton, Stack, Tooltip, Typography, Unstable_Grid2 as Grid} from '@mui/material'
import {useMeetingContext} from '@zel-labs/coaching'
import {useLqAssessments} from './useLqAssessments'
import {Trans, useTranslation} from 'react-i18next'

export interface MeetingLqDisplayProps {
  assessmentRef: DocumentReference<PartialLQAssessmentRequest>
}


export function MeetingLq() {
  const {assessments} = useLqAssessments()

  if (assessments == null) {
    return null
  }

  if (assessments.length === 0) {
    return null
  }
  return <MeetingLqDisplay assessmentRef={assessments[0].ref}/>
}

export function MeetingLqDisplay({assessmentRef}: MeetingLqDisplayProps) {
  const assessmentSnapshot = useFirestoreSnapshot(assessmentRef)


  const assessment = useMemo(
    () => assessmentSnapshot?.data(),
    [assessmentSnapshot]
  )

  const {meetingTitle} = useMeetingContext()
  const {t} = useTranslation()

  return <Stack direction="column">
    <Typography variant="h3" component="h1">{t('meeting.assessments.lq.title')}</Typography>
    <Typography variant="subtitle1">{meetingTitle ?? <Skeleton/>}</Typography>

    <Typography variant="h4" component="h2" pt={2}><Trans i18nKey="meeting.assessments.lq.summaries" /></Typography>
    <Stack direction="column" gap={2}>{
      (assessment?.result?.summaries ?? [{}]).map(
        (summary, i) =>
          <SummaryDisplay key={i} summary={summary}/>
      )
    }</Stack>

    <Typography variant="h4" component="h2" pt={4}><Trans i18nKey="meeting.assessments.lq.qualities" /></Typography>
    <Grid container spacing={4}>
      {
        (assessment?.result?.assessment ?? [{}, {}]).map(
          (assessment, i) =>
            <Grid xs={12} lg={6} key={i}><AssessmentDisplay key={i} assessment={assessment}/></Grid>
        )
      }
    </Grid>
  </Stack>
}

function SummaryDisplay({summary}: { summary: Partial<InterviewSummary> }) {
  return <Box>
    <Typography variant="h5" component="h3">{summary.name ?? <Skeleton/>}</Typography>
    <Typography variant="body2" component="h2">{summary.summary ?? <Skeleton/>}</Typography>
  </Box>
}


function AssessmentDisplay({assessment}: { assessment: Partial<QualityAssessment> }) {
  const conficenceSign = assessment.confidence === 'Mid' ? '?' :
    assessment.confidence === 'Low' ? '??' : null

  const spec = useMemo(
    () => assessment?.qualityId == null
      ? null
      : assessment.qualityId in qualities
        ? qualities[assessment.qualityId]
        : {
          name: assessment.qualityName ?? 'N/A',
          description: 'User or model fabricated quality'
        },
    [assessment]
  )
  return <Box>
    <Tooltip
      title={spec != null ? <Box p={2}>
        <Typography
          variant="h5" component="p">{spec.name}</Typography>
        {assessment?.qualityName != null && spec.name !== assessment.qualityName && <Typography
          variant="subtitle2" component="p">({assessment?.qualityName})</Typography>}
        <Typography
          variant="body2">{spec?.description}</Typography>
      </Box> : undefined}>
      <Typography variant="h5" component="h3">
        {spec?.name ?? assessment.qualityName ?? <Skeleton/>}
      </Typography>
    </Tooltip>
    <Typography variant="overline" lineHeight={1} fontWeight="bold">{assessment.rating ??
      <Skeleton/>}{conficenceSign != null
      ? <Typography component="span" variant="overline" sx={{verticalAlign: 'super'}} lineHeight={1}
                    fontWeight="normal">(<b>{conficenceSign}</b>)</Typography>
      : null}
    </Typography>
    <Typography variant="body2" component="p">{assessment.reasoning ?? <Skeleton/>}</Typography>
  </Box>
}

import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import languageDetector from 'i18next-browser-languagedetector'

import * as en from './en'
import * as hu from './hu'

export const defaultNS = 'home'

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en,
      hu
    },
    fallbackLng: 'en-US',
    ns: ['home', 'languages', 'auth'],
    defaultNS,
    interpolation: {
      escapeValue: false,
      format: (value, format?, lng?) => {
        if (value instanceof Date) {
          if (format === 'date') {
            return new Intl.DateTimeFormat(lng, {dateStyle: 'short'}).format(value)
          }
          return new Intl.DateTimeFormat(lng).format(value)
        }
        return value
      }
    }
  })

export abstract class HttpError extends Error {
  protected constructor(
    public readonly title: string,
    public override message: string,
    public readonly status: number,
    options?: { cause?: unknown },
    public readonly context?: unknown){
    // @ts-expect-error options is not known
    super(message, options)
  }
}

import {Link, Typography} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import {Routing} from '@zel-labs/routing'
import {useMeetingContext} from '@zel-labs/coaching'
import {Trans} from 'react-i18next'


export function LqBreadcrumb() {
  const {meeting} = useMeetingContext()
  if (meeting == null) {
    return null
  }
  return <Link component={RouterLink} to={Routing.lq(meeting.ref)}><Typography
    variant="body2"><Trans i18nKey="meeting.assessments.lq.title"/></Typography></Link>
}

import type {DocumentSnapshot,} from 'firebase/firestore'
import type {Meeting} from '@zel-labs/shared/model'
import {createContext, useContext, useMemo} from 'react'
import type {StorageReference} from 'firebase/storage'
import {getStorage, ref} from 'firebase/storage'
import {useFirebaseApp} from '@zel-labs/firebase-chat'

interface MeetingContextType {
  meeting: DocumentSnapshot<Meeting> | undefined | null
  data: Meeting | undefined | null
  meetingRecordingBlobReference: StorageReference | undefined | null
  meetingDate: Date | undefined
  meetingTitle: string | null | undefined
}

export const MeetingContext = createContext<MeetingContextType | undefined>(undefined)

interface MeetingContextProviderProps extends React.PropsWithChildren {
  meeting: DocumentSnapshot<Meeting> | undefined | null
}

export function MeetingContextProvider({meeting, children}: MeetingContextProviderProps) {

  const app = useFirebaseApp()

  const data = useMemo(
    () => meeting?.data(),
    [meeting]
  )

  const meetingRecordingBlobReference = useMemo(
    () => {
      const storage = getStorage(app)
      return data?.recording != null ? ref(storage, data.recording) : data?.recording
    },
    [data, app]
  )

  const meetingDate = (data?.appointmentTime ?? data?.uploadedAt)?.toDate()

  const meetingTitle = useMemo(() => {
    if (meeting != null) {
      const data = meeting.data()
      if (data != null) {
        return data.title ?? data.recordingFileName
      }
    }
  }, [meeting])

  return (
    <MeetingContext.Provider value={{meeting, data, meetingRecordingBlobReference, meetingDate, meetingTitle}}>
      {children}
    </MeetingContext.Provider>
  )
}

export function useMeetingContext() {
  const context = useContext(MeetingContext)
  if (context == null) {
    throw new Error('Cannot use Meeting Context outside of a provider')
  }
  return context
}

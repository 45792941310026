import {Container, Stack, styled, Typography} from '@mui/material'
import type {ErrorInfo, ReactNode} from 'react'
import {Component} from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import type {DocumentReference} from 'firebase/firestore'
import {addDoc, collection, getFirestore, serverTimestamp} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import {HttpError} from './HttpError'
import {InternalError} from './errors'
import {FirebaseAppContext} from '../firebase'


interface ErrorBoundaryProps {
  children?: ReactNode
  errorComponent?: (props: { error: HttpError }) => ReactNode
}

interface ErrorBoundaryState {
  error?: Error;
  errorInfo?: ErrorInfo;
  reference?: DocumentReference
}

export class FirestoreReportingErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private ErrorComponent: ((props: { error: HttpError }) => React.ReactNode) | (({error}: {
    error: HttpError
  }) => ReactNode)

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.ErrorComponent = props.errorComponent ?? ErrorComponent
  }

  readonly state: ErrorBoundaryState = {}
  static contextType = FirebaseAppContext
  declare context: React.ContextType<typeof FirebaseAppContext>

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {error}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({error, errorInfo})
    this.reportError(error, errorInfo)
  }

  private async reportError(error: Error, errorInfo: ErrorInfo) {
    if (this.context == null) {
      throw new Error('Firebase context not found')
    }
    const {app} = this.context
    const firestore = getFirestore(app)
    const auth = getAuth(app)
    const user = auth.currentUser
    const errors = collection(firestore, 'errors')
    const payload = {
      user: user?.uid ?? null,
      timestamp: serverTimestamp(),
      stack: error.stack ?? null,
      message: error.message,
      name: error.name,
      href: window.location.href,
      componentStack: errorInfo.componentStack ?? null,
      digest: errorInfo.digest ?? null,
      context: error instanceof HttpError ? error.context : null
    }

    try {
      const reference = await addDoc(errors, payload)
      this.setState({reference})
    } catch (error) {
      console.error('Error reporting error', error)
    }
  }

  public render() {
    if (this.state.error != null) {
      const error = this.state.error instanceof HttpError
        ? this.state.error
        : new InternalError()

      return <this.ErrorComponent error={error}/>
    }

    return this.props.children
  }
}

function ErrorComponent({error}: { error: HttpError }) {
  return <ErrorContainer maxWidth="sm">
    <Stack direction="row" gap={2} alignItems="center" justifyContent="stretch" width="100%">
      <H3ErrorIcon/>
      <Typography variant="h3" component="h1">{error.status} {error.title} </Typography>
    </Stack>
    <Typography variant="body2">{error.message}</Typography>
  </ErrorContainer>
}

const H3ErrorIcon = styled(ErrorIcon, {
  name: 'H3ErrorIcon',
  slot: 'Root'
})(({theme}) => ({
  color: theme.palette.error.main,
  fontSize: theme.typography.h3.fontSize
}))

const ErrorContainer = styled(Container, {
  name: 'ErrorContainer',
  slot: 'Root'
})(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100vw', height: '100vh', overflow: 'hidden'
}))



import React, {useMemo} from 'react'
import {Breadcrumbs,  HomeBreadcrumb,  PageContainer} from '@zel-labs/shared/mui'

import {useParams} from 'react-router-dom'
import {CoachingBreadcrumb, CoachingContextProvider, useCoaching} from '../coaching'
import { useMeeting } from './useMeeting'
import { MeetingContextProvider } from './MeetingContext'
import { MeetingBreadcrumb } from './MeetingBreadcrumb'
import {MeetingDetails} from './MeetingDetails'



export function MeetingPage() {
  const {coachingId, meetingId} = useParams<{ coachingId: string, meetingId: string }>()

  const {coachingSnapshot, role} = useCoaching(coachingId)
  const {meetingSnapshot} = useMeeting(meetingId, coachingSnapshot)


  const title = useMemo(() => {
    if (meetingSnapshot != null) {
      const data = meetingSnapshot.data()
      if (data != null) {
        return data.title ?? data.recordingFileName
      }
    }
  }, [meetingSnapshot])

  if (role == null) {
    return null
  }

  return <PageContainer title={title ?? 'MAX'}>
    <CoachingContextProvider coaching={coachingSnapshot} role={role}>
      <MeetingContextProvider meeting={meetingSnapshot}>
        <Breadcrumbs separator="›">
          <HomeBreadcrumb/>
          <CoachingBreadcrumb/>
          <MeetingBreadcrumb/>
        </Breadcrumbs>
        <MeetingDetails/>
      </MeetingContextProvider>
    </CoachingContextProvider>
  </PageContainer>
}

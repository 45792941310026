import { Button, DialogActions, DialogContent, DialogTitle, FormHelperText, Stack, TextField } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import type { FormEvent } from 'react'
import { useCallback, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useAuthenticationContext } from '..'
import { useFirebaseAuth } from '@zel-labs/firebase-chat'
import { FirebaseError } from 'firebase/app'
import { errorMessage } from './util'
import { sendPasswordResetEmail } from 'firebase/auth'

export function PasswordResetForm() {
  const { t } = useTranslation()
  const { auth } = useFirebaseAuth()
  const { signIn } = useAuthenticationContext()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)


  const [state, setState] = useState<'idle' | 'pending' | 'success' | 'failed'>('idle')
  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!['idle', 'failed'].includes(state)) {
        throw new Error(`Cannot submit while in state ${state}`)
      }

      setState('pending')
      setError(null)
      setEmailError(null)
      try {
        const url = new URL(window.location.href)
        url.searchParams.set('email', email)
        await sendPasswordResetEmail(auth, email, { url: url.toString() })
        setState('success')
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (e.code === 'auth/invalid-email') {
            setEmailError('Invalid email')
          } else {
            setError(errorMessage(e))
          }
        } else {
          setError(errorMessage(e))
        }
        setState('failed')
      }
    },
    [auth, email, state]
  )

  return <form onSubmit={submit}>
    <DialogTitle><Trans i18nKey="auth:resetPassword.form.title" /></DialogTitle>

    <DialogContent>
      <Stack gap={1} flexGrow={1}>

        {state === 'success'
          ? <div><Trans i18nKey="auth:resetPassword.form.success" /></div>
          : <TextField
            size="small" fullWidth={true} margin="normal"
            label={t('auth:resetPassword.form.email')} value={email}
            error={emailError != null} helperText={emailError}
            onChange={e => setEmail(e.target.value)} />
        }
        {error != null && <FormHelperText error={true}>{error}</FormHelperText>}

      </Stack>
    </DialogContent>
    <DialogActions>

      {state === 'success'
        ? <LoadingButton variant="contained" color="primary" onClick={signIn}><Trans
          i18nKey="auth:resetPassword.form.back" /></LoadingButton>
        :
        <>
          <Button variant="outlined" onClick={signIn} size="small"><Trans
            i18nKey="auth:resetPassword.form.cancel" /></Button>
          <LoadingButton sx={{ flexShrink: 0 }} type="submit" variant="contained" color="primary"
                         loading={state === 'pending'} autoFocus={true}>
            <Trans i18nKey="auth:resetPassword.form.submit" />
          </LoadingButton>
        </>
      }
    </DialogActions>
  </form>
}


import {createTheme} from '@mui/material/styles'

import '@fontsource-variable/urbanist'
import '@fontsource-variable/inter'
import {colors} from '@mui/material'

const designFont = 'Urbanist Variable, sans-serif'
const bodyFont = 'Inter Variable, sans-serif'

export const zelTheme = createTheme({
  palette: {
    primary: {
      main: '#792bd8'
    },
    secondary: {
      main: colors.amber[500]
    },
    background: {
      default: colors.common.white,
      paper: colors.grey[100]
    },
  },
  shape: {
    borderRadius: 10
  },
  typography: {
    fontFamily: bodyFont,
    h1: {fontFamily: designFont},
    h2: {fontFamily: designFont},
    h3: {fontFamily: designFont},
    h4: {fontFamily: designFont},
    h5: {fontFamily: designFont},
    h6: {fontFamily: designFont},
    subtitle1: {fontFamily: designFont},
    subtitle2: {fontFamily: designFont}
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#792bd8',
          color: colors.common.white

        }
      }
    }
  }
})

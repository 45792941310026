import {Link, Skeleton, Typography} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import {Routing} from '@zel-labs/routing'
import {useMeetingContext} from '../meeting'

export function CopilotBreadcrumb() {
  const {meeting, meetingTitle} = useMeetingContext()
  if (meeting == null) {
    return null
  }
  return <Link component={RouterLink} to={Routing.copilot(meeting.ref)}><Typography
    variant="body2">Copilot</Typography></Link>
}

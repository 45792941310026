import type {User, Auth} from 'firebase/auth'
import type {PropsWithChildren} from 'react'
import {createContext, useContext, useEffect, useState} from 'react'
import {useFirebaseAuth} from '@zel-labs/firebase-chat'


export type UserRole = 'admin'

export interface UserContextType { // extends FirebaseAuthContextType
  user?: User | null;
  state: 'pending' | 'success' | 'error';
  auth: Auth;
  roles: UserRole[];
}

export const UserContext =
  createContext<UserContextType | undefined>(undefined)

export function UserContextProvider({children}: PropsWithChildren) {
  const firebaseAuth = useFirebaseAuth()
  const [roles, setRoles] = useState<UserRole[]>([])
  useEffect(() => {
    const {user} = firebaseAuth
    if (user != null) {
      user?.getIdTokenResult().then((token) => {
        if ('roles' in token.claims) {
          setRoles(token.claims.roles as UserRole[])
        }
      })
    }
  }, [firebaseAuth])


  return <UserContext.Provider value={{...firebaseAuth, roles}}>{children}</UserContext.Provider>
}

export function useUserContext() {
  const context = useContext(UserContext)
  if (context == null) {
    throw new Error('useAuthContext must be used within an AuthContextProvider')
  }
  return context
}

import {useParams} from 'react-router-dom'

import {Typography} from '@mui/material'
import {
  CoachingBreadcrumb,
  CoachingContextProvider, MeetingBreadcrumb,
  MeetingContextProvider,
  useCoaching,
  useMeeting,
  useMeetingContext
} from '@zel-labs/coaching'
import {Breadcrumbs, HomeBreadcrumb, PageContainer} from '@zel-labs/shared/mui'
import {LqBreadcrumb} from './LqBreadcrumb'
import {MeetingLq} from './MeetingLq'
import {useTranslation} from 'react-i18next'

export function LqPage() {
  const {coachingId, meetingId} = useParams<{ coachingId: string, meetingId: string }>()
  const {coachingSnapshot, role} = useCoaching(coachingId)
  const {meetingSnapshot} = useMeeting(meetingId, coachingSnapshot)

  if (role == null) {
    return null
  }

  return <CoachingContextProvider coaching={coachingSnapshot} role={role}>
    <MeetingContextProvider meeting={meetingSnapshot}>
      <LqLayout/>
    </MeetingContextProvider>
  </CoachingContextProvider>
}

function LqLayout() {
  const {data} = useMeetingContext()
  const {t} = useTranslation()

  return <PageContainer title={data?.title ?? data?.recordingFileName ?? t('meeting.assessments.lq.title')}>
    <Breadcrumbs separator="›">
      <HomeBreadcrumb/>
      <CoachingBreadcrumb/>
      <MeetingBreadcrumb/>
      <LqBreadcrumb/>
    </Breadcrumbs>
    <MeetingLq/>
  </PageContainer>
}

import type {
  CollectionReference,
  Query,
  QueryDocumentSnapshot
} from 'firebase/firestore'
import {getDocs, onSnapshot} from 'firebase/firestore'
import type {HttpError} from '../errors'
import { Unauthorized} from '../errors'
import {Forbidden, InternalError} from '../errors'
import {useEffect, useState} from 'react'
import {useFirebaseAuth} from '../firebase'


interface FirestoreResultsOptions {
  watch?: boolean;
  waitForWrites?: boolean;
}

const defaultOptions = {
  watch: true,
  waitForWrites: false
}

export function useFirestoreResults<T>(
  query: Query<T> | CollectionReference<T> | undefined|null,
  options?: Partial<FirestoreResultsOptions>):
  QueryDocumentSnapshot<T>[] | undefined | null {
  const [results, setResults] =
    useState<QueryDocumentSnapshot<T>[] | null>()

  const [error, setError] = useState<HttpError | null>()
  const {user} = useFirebaseAuth()


  useEffect(() => {
    if (query == null) {
      setResults(null)
      return
    }
    getDocs(query)
      .then(
        (snapshot) => {
          setResults(snapshot.docs)
        }
      )
      .catch(
        (error) => {
          if (error.code === 'permission-denied') {
            if (user === null) {
              setError(new Unauthorized(error, {query: JSON.stringify(query)}))
            } else {
              setError(new Forbidden(error, {query: JSON.stringify(query)}))
            }
          } else {
            setError(new InternalError(error, {query: JSON.stringify(query)}))
          }
        }
      )
  }, [query, user])


  useEffect(
    () => {
      const watch = options?.watch ?? defaultOptions.watch
      const waitForWrites = options?.waitForWrites ?? defaultOptions.waitForWrites
      if (query != null && watch) {
        const unsubscribe = onSnapshot(query,{ includeMetadataChanges: true }, (snapshot) => {
          if (!waitForWrites || !snapshot.metadata.hasPendingWrites) {
            setResults(snapshot.docs)
          }
        })

        return () => {
          unsubscribe()
        }
      }
    },
    [query, options?.waitForWrites, options?.watch]
  )


  useEffect(
    () => {
      if (error != null) {
        throw error
      }
    },
    [error]
  )

  return results
}

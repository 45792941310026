import CreateIcon from '@mui/icons-material/Create'
import { Button, Divider, List, ListItem, ListItemButton, ListItemText, Stack, styled, Toolbar } from '@mui/material'
import { useChatsContext } from '../model/ChatsContext'

export function ChatList() {
  const { chats, selectedChat, selectChat } = useChatsContext()

  return <ChatListStack direction="column" gap={2}>
    <List dense={true} disablePadding={true}>{
      chats?.map(
        (chat) =>
          <ListItem key={chat.id} disableGutters={true} disablePadding={true}>
            <ListItemButton selected={selectedChat?.path===chat.ref.path} dense={true}
                            onClick={() => selectChat(chat.ref)}>
              <ChatItemText primary={chat.data()?.title} />
            </ListItemButton>
          </ListItem>)

    }
      {selectedChat==null &&
        <ListItem key={'new'} disableGutters={true} disablePadding={true}>
          <ListItemButton selected={selectedChat==null} dense={true}>
            <ChatItemText primary={'New Chat'} />
          </ListItemButton>
        </ListItem>
      }
    </List>
    <Divider flexItem={true} />
    <Toolbar sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }} variant="dense">
      <Button endIcon={<CreateIcon />} variant="text" size="small"
              onClick={() => selectChat(null)}>Start new Chat</Button>
    </Toolbar>
  </ChatListStack>
}

const ChatListStack = styled(
  Stack,
  { name: 'ChatListStack', slot: 'Root' }
)(
  ({ theme }) => ({
    overflowY: 'auto',
  })
)

const ChatItemText = styled(ListItemText, { name: 'ChatItem', slot: 'Root' })(({ theme }) => ({
  '& .MuiListItemText-primary': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.typography.body2.fontSize
  }
}))


import {Fab, styled} from '@mui/material'

export const BottomFab = styled(Fab, {name: 'StaticFab', slot: 'Root'})(
  ({theme}) => ({
    zIndex: 2000,
    marginTop: theme.spacing(2),
    position: 'fixed',
    bottom: theme.spacing(3),
    left: '50%',
    transform: 'translateX(-50%)',
    margin: 'auto',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    }
  })
)

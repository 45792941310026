import type {ContentComponentType} from '../model'
import {ChatContextProvider, useChatContext} from '../model'

import {Box, Container, styled} from '@mui/material'
import {ThreadView} from './ThreadView'
import {PromptForm} from './PromptForm'
import {SamplePrompts} from './SamplePrompts'
import {useChatsContext} from '../model/ChatsContext'
import {useFirebaseAuth} from '../../firebase'


export interface ChatViewProps {
  mode: 'chat' | 'view';
  ContentComponent?: ContentComponentType;
  samplePrompts?: string[]
}

export function ChatView({mode, ...props}: ChatViewProps) {
  const {selectedChat} = useChatsContext()
  if (selectedChat === undefined) {
    return null
  }

  return <ChatContextProvider {...props} mode={mode}>
    <ChatContainer maxWidth="md">
      <Messages mode={mode}/>
      <Prompt mode={mode}/>
    </ChatContainer>
  </ChatContextProvider>
}

function Messages({mode}: { mode: ChatViewProps['mode'] }) {
  const {selectedChat} = useChatsContext()

  if (mode === 'view') {
    return null
  }

  if (selectedChat === null) {
    return <MessagesContainer><SamplePrompts/></MessagesContainer>
  }
  return <MessagesContainer><ChatMessages key={selectedChat?.id ?? 'new'}/></MessagesContainer>
}

function Prompt({mode}: { mode: ChatViewProps['mode'] }) {
  const {chat} = useChatContext()
  const {user} = useFirebaseAuth()

  const readonly = mode === 'view' || (chat != null && (user == null || chat?.data()?.created_by !== user.uid))
  if (readonly) {
    return null
  }

  return <PromptFormContainer><PromptForm/></PromptFormContainer>

}

export function ChatMessages() {
  const chatContext = useChatContext()

  const {thread, chat} = chatContext

  if (chat == null || thread == null || thread.length === 0) {
    return null
  }
  return <>{thread.map(
    (tree, i) => {
      const activeChild = i < thread.length - 1
        ? thread[i + 1]
        : null
      return <ThreadView key={tree?.message.id ?? chat.id}
                         tree={tree} activeChild={activeChild}/>
    }
  )}</>
}

const MessagesContainer = styled(Box, {name: 'MessagesContainer', slot: 'Root'})(({theme}) => ({
  overflowY: 'auto', // Become scrollable vertically
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4), // Space between messages,
  flexGrow: 1
}))


const ChatContainer = styled(Container, {name: 'ChatContainer', slot: 'Root'})(
  ({theme}) => ({
    display: 'flex',
    flexDirection: 'column', justifyContent: 'stretch',
    overflow: 'hidden', gap: theme.spacing(2),
    flexGrow: 1,
  })
)


const PromptFormContainer = styled(Box, {name: 'UserMessage', slot: 'Root'})(({theme}) => ({
  flexShrink: 0,
  flexGrow: 0,
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius
}))


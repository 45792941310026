import {Chip, styled} from '@mui/material'
import type {ChipProps} from '@mui/material/Chip'
import type {LinkProps as RouterLinkProps} from 'react-router-dom'

export const ActionChip = styled(Chip)<ChipProps & RouterLinkProps>(
  ({theme}) => ({
    '& .MuiChip-icon': {
      marginLeft: theme.spacing(1),
    },
  })
)

import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import type {ReactNode} from 'react'
import {useState} from 'react'
import {Stack, styled, Typography} from '@mui/material'
import MicIcon from '@mui/icons-material/Mic'
import UploadIcon from '@mui/icons-material/Upload'
import AddIcon from '@mui/icons-material/Add'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {Routing} from '@zel-labs/routing'
import {useCoachingContext} from '../coaching'

export function NewMeetingSpeedDial() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {coaching, role} = useCoachingContext()

  if (role !== 'coach') {
    return null
  }

  return <NewMeetingSpeedDialFab
    ariaLabel={t('meeting.create-meeting.cta')}
    FabProps={{variant: 'extended', color: 'error'}}
    icon={<><SpeedDialIcon icon={<AddIcon/>}/><Trans i18nKey="meeting.create-meeting.cta"/></>}
    onClose={handleClose}
    onOpen={handleOpen}
    open={open}
  >{coaching != null &&
    <NewMeetingSpeedDialAction
      icon={<NewMeetingSpeedDialIcon icon={<UploadIcon fontSize="small" color="primary"/>}
                                     label={t('meeting.create-meeting.upload')}/>}
      FabProps={{variant: 'extended'}}
      onClick={() => navigate(Routing.uploadMeeting(coaching?.ref))}
    />}
    {coaching != null &&
      <NewMeetingSpeedDialAction
        icon={<NewMeetingSpeedDialIcon icon={<MicIcon fontSize="small" color="error"/>}
                                       label={t('meeting.create-meeting.record')}/>}
        FabProps={{variant: 'extended'}}
        onClick={() => navigate(Routing.recordMeeting(coaching?.ref))}
      />}
  </NewMeetingSpeedDialFab>
}

function NewMeetingSpeedDialIcon({icon, label}: { icon: ReactNode, label: string }) {
  return <Stack direction="row" flexGrow={1} justifyContent="flex-start">
    {icon}
    <Typography variant="body2">{label}</Typography>
  </Stack>

}

const NewMeetingSpeedDialAction = styled(SpeedDialAction, {name: 'NewMeetingSpeedDialAction'})(
  ({theme}) => ({
    '&.MuiSpeedDialAction-fab': {
      padding: theme.spacing(2),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1)
      },
      '& .MuiTypography-root': {
        // fontSize: theme.typography.caption.fontSize,
        // textTransform: 'none'
      }
    }
  })
)

const NewMeetingSpeedDialFab = styled(SpeedDial, {name: 'NewMeetingSpeedDialFab'})(
  ({theme}) => ({
    zIndex: 2000,
    marginTop: theme.spacing(2),
    position: 'fixed',
    bottom: theme.spacing(3),
    left: 0,
    right: 0,
    margin: 'auto',
  })
)

import type {DocumentSnapshot} from 'firebase/firestore'
import type {Meeting} from '@zel-labs/shared/model'
import React, {useMemo} from 'react'
import {Button} from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {Link as RouterLink} from 'react-router-dom'
import {Routing} from '@zel-labs/routing'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import {useCoachingContext} from '../coaching'
import {Trans} from 'react-i18next'

interface CopilotButtonProps {
  meeting: DocumentSnapshot<Meeting> | null | undefined;
}

export function CopilotButton({meeting}: CopilotButtonProps) {
  const {role, coaching} = useCoachingContext()

  const isDeleting = useMemo(() => ['delete', 'deleting'].includes(coaching?.data()?.status ?? 'n/a'), [coaching])

  const transcript = useMemo(() => meeting?.data()?.transcript, [meeting])
  return (
    <Button color={role === 'coachee' ? 'error' : 'primary'}
            disabled={transcript == null || meeting == null || isDeleting}
            to={meeting != null ? Routing.copilot(meeting.ref) : '#'}
            size="small" variant="contained"
            startIcon={transcript != null ? <AutoAwesomeIcon/> : <HourglassBottomIcon/>}
            component={RouterLink}><Trans i18nKey="copilot.cta"/></Button>
  )
}

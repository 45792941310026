import { useCallback, useMemo } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import type { DocumentReference } from 'firebase/firestore'
import {useFirebaseApp} from '@zel-labs/firebase-chat'
import type {Invitation} from '@zel-labs/shared/model'


export function useFunctions() {
  const app = useFirebaseApp()
  const functions = useMemo(
    () => getFunctions(app, 'europe-west1'),
    [app]
  )
  const acceptInvitation = useCallback(
    async (invitationRef: DocumentReference<Invitation>) => {
      const accept_invitation = httpsCallable<{ id: string }, { success: boolean }>(functions, 'accept_invitation')
      const result = await accept_invitation({
        id: invitationRef.id
      })
      return result.data

    }, [functions])

  return { acceptInvitation }
}

import {Box, Skeleton, Typography} from '@mui/material'

interface ContentHeaderProps {
  title: string | undefined | null
  subtitle?: string  | null
}

export function ContentHeader(props: ContentHeaderProps) {

  const {title, subtitle} = props
  return <Box mb={4}>
    <Typography variant="h4" component="h2">{title ?? <Skeleton/>}</Typography>
    {('subtitle' in props && subtitle !== null) &&
      <Typography variant="caption" sx={{marginTop: '0 !important'}}>{subtitle ?? <Skeleton/>}</Typography>}
  </Box>
}

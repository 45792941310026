import {useCoachingContext, useMeetingContext} from '@zel-labs/coaching'
import {typeConverter, useFirestoreResults} from '@zel-labs/firebase-chat'
import {useCallback, useMemo} from 'react'
import {addDoc, collection, query, where} from 'firebase/firestore'
import type {PartialLQAssessmentRequest} from './types'

export function useLqAssessments() {
  const {coaching} = useCoachingContext()
  const {meeting, data} = useMeetingContext()

  const assessmentCollection = useMemo(
    () => {
      if (coaching != null) {
        return collection(coaching.ref, 'assessments')
      }
    },
    [coaching]
  )
  const assessmentsQuery = useMemo(
    () => {
      if (assessmentCollection != null && meeting != null) {
        return query(
          assessmentCollection,
          where('assessmentType', '==', 'lq'),
          where('meetingRef', '==', meeting.ref)
        ).withConverter(typeConverter<PartialLQAssessmentRequest>())
      }
    },
    [assessmentCollection, meeting]
  )

  const assessments = useFirestoreResults(assessmentsQuery)

  const requestAssessment = useCallback(
    () => {
      if (assessmentCollection != null && assessments?.length === 0 && meeting != null && data?.transcript !=null ) {
        addDoc(assessmentCollection, {
          'assessmentType': 'lq',
          'meetingRef': meeting.ref,
        })
      }
    },
    [assessmentCollection, assessments, data, meeting]
  )

  return {assessments, requestAssessment}
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMeetingContext } from './MeetingContext'
import { typeConverter, useFirebaseAnalytics, useFirebaseApp, useFirestoreResults } from '@zel-labs/firebase-chat'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { Button, Container, Divider, IconButton, InputAdornment, Skeleton, Stack, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import type { DocumentSnapshot, Timestamp } from 'firebase/firestore'
import { addDoc, collection, onSnapshot, orderBy, query, serverTimestamp, where } from 'firebase/firestore'
import { useUserContext } from '@zel-labs/auth'
import type { Meeting } from '@zel-labs/shared/model'
import { Trans, useTranslation } from 'react-i18next'
import { useCoachingContext } from '../coaching'
import { trackEvent } from '@zel-labs/shared/firebase'


type searchState = 'idle' | 'searching' | 'succeeded' | 'failed'


interface QuotePosition {
  start: number;
  duration: number;
  quote: string;
}


export interface QuoteSearchQuery {
  query: string;
  timestamp: Timestamp;
  user: string;
  result?: QuotePosition;
}


export function QuoteSearch() {
  const { role } = useCoachingContext()
  const { meeting } = useMeetingContext()
  const { user } = useUserContext()
  const { t } = useTranslation()

  const searchesRef = useMemo(
    () => {
      if (meeting != null && user != null) {
        return collection(meeting.ref, 'searches').withConverter(typeConverter<QuoteSearchQuery>())
      }

    }, [meeting, user]
  )


  const userSearchesRef = useMemo(
    () => {
      if (meeting != null && user != null && searchesRef != null) {
        if (role === 'admin') {
          return query(
            searchesRef,
            orderBy('timestamp', 'desc')
          )
        }
        return query(
          searchesRef,
          where('user', '==', user.uid),
          orderBy('timestamp', 'desc')
        )
      }

    }, [meeting, role, searchesRef, user]
  )

  const searches = useFirestoreResults(userSearchesRef)

  const [queryString, setQueryString] = useState<string>('')
  const { analytics } = useFirebaseAnalytics()
  const startSearch = useCallback(
    async () => {
      if (searchesRef != null && user != null) {
        trackEvent(analytics, 'quote_search_submitted', {
          coaching: meeting?.ref.parent.parent?.path,
          meeting: meeting?.ref.path,
          query: queryString
        })
        const search = await addDoc(searchesRef, {
          query: queryString,
          user: user.uid,
          timestamp: serverTimestamp()
        })
        setQueryString('')
      }
    },
    [searchesRef, user, analytics, meeting, queryString]
  )

  if (meeting == null) {
    return null
  }

  return <Stack direction="column" gap={2}>
    <Typography variant="h3"><Trans i18nKey="meeting.quote-search.title" /></Typography>
    <Container maxWidth="md">
      <TextField
        multiline={true}
        fullWidth={true}
        label={t('meeting.quote-search.query')}
        variant="filled" value={queryString} size="small"
        onChange={(e) => setQueryString(e.target.value)}
        margin="normal"
      />
      <Stack direction="row" justifyContent="flex-end">
        <Button disabled={queryString === ''} onClick={startSearch} variant="contained"
                startIcon={<SearchIcon />}>Search</Button>
      </Stack>
    </Container>
    <Divider />
    <Container maxWidth="lg">
      <Stack direction="column" gap={4} divider={<Divider />}>
        {searches?.map((search) =>
          <SearchResult key={search.id} search={search} meeting={meeting} />)}
      </Stack>
    </Container>
  </Stack>
}

function SearchResult({ search, meeting }: {
  search: DocumentSnapshot<QuoteSearchQuery>,
  meeting: DocumentSnapshot<Meeting>
}) {
  const data = useMemo(
    () => search.data(),
    [search]
  )

  if (data == null) {
    return null
  }

  return <Stack direction="column" gap={1}>
    <Typography variant="body1" fontStyle="italic">{data.result?.quote ?? <Skeleton />}</Typography>
    <Stack direction="row" justifyContent="center">
      {
        data.result != null
          ? <ReplayQuote search={search} meeting={meeting} />
          : <Skeleton />
      }
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body2" color="primary">{data.query}</Typography>
      <Typography textAlign="right" variant="caption"><Trans i18nKey="formats.short-date"
                                                             values={{ date: data.timestamp?.toDate() }} /> </Typography>
    </Stack>
  </Stack>
}


export function ReplayQuote({ search, meeting }: {
  search: DocumentSnapshot<QuoteSearchQuery>,
  meeting: DocumentSnapshot<Meeting>
}) {
  const audioPath = useMemo(
    () => meeting?.data()?.audio,
    [meeting]
  )

  const audioUrl = useAudioUrl(audioPath)


  const fragmentUrl = useMemo(
    () => {
      const result = search.data()?.result
      if (audioUrl == null || result == null) {
        return null
      }


      const { start, duration } = result
      return `${audioUrl}#t=${Math.floor(start)},${Math.ceil(start + duration)}`
    },
    [audioUrl, search]
  )

  if (fragmentUrl == null) {
    return <Skeleton />
  }
  return <audio
    id={`quote-${search.id}`}
    controls={true}
    autoPlay={false}
    controlsList="nofullscreen nodownload">
    <source src={fragmentUrl} />
  </audio>
}

export function ReplayMeeting() {
  const { meeting } = useMeetingContext()

  const { user } = useUserContext()
  const audioPath = useMemo(
    () => meeting?.data()?.audio,
    [meeting]
  )

  const audioUrl = useAudioUrl(audioPath)
  const [player, setPlayer] = useState<HTMLAudioElement | null>(null)
  const [query, setQuery] = useState<string | null>(null)
  const [position, setPosition] = useState<QuotePosition>()
  const [state, setState] = useState<searchState>('idle')


  const runQuery = useCallback(
    async () => {
      if (query != null && query !== '' && meeting != null && user != null) {
        const queries = collection(meeting.ref, 'searches')
          .withConverter(typeConverter<QuoteSearchQuery>())
        const search = await addDoc(queries, {
          query, user: user.uid, timestamp: serverTimestamp()
        })
        setState('searching')
        const unsubscribe = onSnapshot(search, (snapshot) => {
          const position = snapshot.data()?.result
          if (position != null) {
            setPosition(position)
            setState('succeeded')
            if (player != null) {
              player.currentTime = position.start
              player.play()
            }
            unsubscribe()
          }
        })
      }
    },
    [query, meeting, setPosition, user, player]
  )

  const handleMouseDownQuery = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  if (audioUrl == null) {
    return null
  }

  return <>
    <audio ref={setPlayer} controls={true} controlsList="nofullscreen nodownload">
      <source src={audioUrl} />
      Your browser does not support the audio element.
    </audio>
    <Stack direction="row">
      <TextField
        label="search"
        disabled={state !== 'idle'}
        variant="filled" value={query ?? ''} size="small"
        onChange={(event) => setQuery(event.target.value)}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                disableRipple={true}
                disabled={state !== 'idle' || query == null || query.trim() === '' || user == null}
                aria-label="search"
                onClick={runQuery}
                onMouseDown={handleMouseDownQuery}
                edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
        }}
      />
    </Stack>
  </>
}


function useAudioUrl(path: string | null | undefined) {
  const app = useFirebaseApp()
  const [url, setUrl] = useState<string | null>()

  useEffect(
    () => {
      if (path != null) {

        const storage = getStorage(app)
        const reference = ref(storage, path)
        getDownloadURL(reference).then(setUrl)
      }
    },
    [app, path]
  )

  return url
}

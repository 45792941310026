import MaxIcon from '../assets/max-icon.svg'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { AppBar as MuiAppBar, Box, Container, Link, Stack, styled, Toolbar } from '@mui/material'
import { Routing } from '@zel-labs/routing'
import { AuthButton, useAppContext } from '@zel-labs/auth'

interface RootProps {
  fullScreen?: boolean;
}

export interface PageContainerProps extends React.PropsWithChildren, RootProps {
  title: string;
}


const Root = styled('div', {
  name: 'Page',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'fullScreen'
})<RootProps>(
  ({ theme, fullScreen }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: fullScreen ? '100vh' : 'auto',
    overflow: 'hidden'
  })
)

export function PageContainer(props: PageContainerProps) {
  const { children, title, fullScreen } = props
  const appContext = useAppContext()
  return <Root fullScreen={fullScreen}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {!appContext.isHeaderless && <PageAppBar title={title} />}
    <ContentContainer disableGutters={fullScreen} fullScreen={fullScreen} maxWidth={fullScreen ? false : 'lg'}>
      {children}
    </ContentContainer>
  </Root>
}

const ContentContainer = styled(Container, {
  name: 'ContentContainer',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'fullScreen'
})<RootProps>(
  ({ theme, fullScreen }) => ({
    backgroundColor: theme.palette.background.default,
    paddingTop: fullScreen ? 0 : theme.spacing(0),
    paddingBottom: fullScreen ? 0 : theme.spacing(4),
    overflow: fullScreen ? 'hidden' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  })
)

function PageAppBar(props: Omit<PageContainerProps, 'children'>) {
  return <AppBar enableColorOnDark={true} color="primary">
    <Toolbar variant="dense">
      <Link sx={{ lineHeight: 1, textDecoration: 'none', display: 'block', height: '40px' }} component={RouterLink}
            to={Routing.home()}>
        <MaxIcon />
      </Link>
      <Title>beta</Title>

      <Stack direction="row" gap={1} alignItems="center">
        <AuthButton />
      </Stack>
    </Toolbar>
  </AppBar>
}

const AppBar = styled(MuiAppBar, {
  name: 'AppBar',
  slot: 'Root'
})(
  ({ theme }) => ({
    position: 'sticky'
  })
)

const Title = styled(Box, {
  name: 'Title',
  slot: 'Root'
})(({ theme }) => ({
  fontFamily: theme.typography.h1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  flexGrow: 1,
  //textTransform: 'uppercase',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

const LogoLink = styled(Link)(({ theme }) => ({
  '& svg': {
    height: '60px',
    width: '60px',
    top: '-10px',
    position: 'relative'
  }
}))


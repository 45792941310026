import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {Trans, useTranslation} from 'react-i18next'

export const availableLanguages = [
  'hu-HU', 'en-US', 'bg-BG', 'bs-BA', 'ca-ES', 'cs-CZ', 'da-DK', 'de-AT', 'de-CH', 'de-DE', 'el-GR', 'en-GB', 'en-IE',
  'es-ES', 'et-EE', 'eu-ES', 'fi-FI', 'fr-BE', 'fr-CH', 'fr-FR', 'hr-HR', 'hy-AM', 'is-IS', 'it-CH', 'it-IT', 'ka-GE',
  'lt-LT', 'lv-LV', 'mk-MK', 'nl-BE', 'nl-NL', 'no-NO', 'pl-PL', 'pt-PT', 'ro-RO', 'ru-RU', 'sk-SK', 'sl-SI', 'sq-AL',
  'sr-RS', 'sv-SE', 'tr-TR', 'uk-UA'
]

export interface LanguageSelectorProps {
  languageCode: string
  setLanguageCode: (languageCode: string) => void
}

export function LanguageSelector({languageCode, setLanguageCode}: LanguageSelectorProps) {
  const {t} = useTranslation()


  return <FormControl fullWidth size="small">
    <InputLabel size="small" shrink={true} id="language-label"><Trans i18nKey="meeting.upload-form.language"/></InputLabel>
    <Select
      size="small" notched={true} fullWidth={true}
      label={t('meeting.upload-form.language')} labelId="language-label"
      value={languageCode}
      onChange={(event) => setLanguageCode(event.target.value as string)}>{
      availableLanguages.map((language) =>
        <MenuItem key={language} value={language} title={t(language, {ns: 'languages'})}><Trans
          i18nKey={language} ns="languages"/></MenuItem>
      )
    }
    </Select>
  </FormControl>
}

import React from 'react'
import {
  Stack
} from '@mui/material'
import {MeetingActions, SummaryMarkdown} from '../components'
import {useMeetingContext} from './MeetingContext'
import {ContentHeader} from '@zel-labs/shared/mui'
import {useTranslation} from 'react-i18next'
import {NewMeetingSpeedDial} from '../components/NewMeetingSpeedDial'
import {QuoteSearch} from './ReplayMeeting'

export function MeetingDetails() {
  const {data, meetingDate} = useMeetingContext()

  const {t} = useTranslation()
  return <Stack direction="column" spacing={2} mb={6}>
    <ContentHeader title={data?.title ?? data?.recordingFileName}
                   subtitle={t('formats.short-date', {date: meetingDate})}/>
    <MeetingActions/>
    <SummaryMarkdown>{data?.meetingNotes}</SummaryMarkdown>
    {data?.status === 'analyzed' && <QuoteSearch/>}
    <NewMeetingSpeedDial/>
  </Stack>
}


import type {DocumentReference} from 'firebase/firestore'
import type {Meeting} from '@zel-labs/shared/model'

export interface LQAssessmentRequest {
  assessmentType: 'lq'
  meetingRef: DocumentReference<Meeting>
  status: 'requested' | 'processing' | 'completed' | 'failed'
  error?: string
  result: LQAssessment
}

export interface LQAssessmentEvaluation {
  evaluator_uid: string
  evaluation: {
    summaries: [
      {
        summaryId: SummaryKey
        feedback: 'good' | 'bad'
        comment: string | null
      }
    ]
    qualities: [
      {
        qualityId: QualityKey
        rating: 'High' | 'Mid' | 'Low' | null
        reasoning: 'good' | 'bad' | null
        comment: string | null
      }
    ]
  }
}

export interface PartialLQAssessmentRequest extends Omit<LQAssessmentRequest, 'result'> {
  result: {
    summaries: Partial<InterviewSummary>[]
    assessment: Partial<QualityAssessment>[]
  }
}

export function isPartialLQAssessmentRequest(request: unknown): request is PartialLQAssessmentRequest {
  if (typeof request !== 'object' || request === null) return false
  if (!('assessment_type' in request)) return false
  if (!('status' in request)) return false
  if (!('result' in request)) return false
  return request.assessment_type === 'lq' && request.status === 'processing' || request.status === 'failed'
}

export interface LQAssessment {
  summaries: InterviewSummary[]
  qualities: QualityAssessment[]
}


export interface InterviewSummary {
  summaryId: SummaryKey
  name: string
  summary: string
}

export interface QualityAssessment {
  qualityId: QualityKey
  qualityName: string
  rating: 'High' | 'Mid' | 'Low'
  confidence: 'High' | 'Mid' | 'Low'
  reasoning: string
}


export interface QualityDef {
  name: string
  description: string
}

export type QualityKey = keyof typeof qualities

export const qualities: Record<string, QualityDef> = {
  'accountable': {
    'name': 'Accountable',
    'description': 'Accountability in leadership is a critical leadership quality that ensures leaders take responsibility for their actions and decisions, ensuring they uphold the commitments they make to others regarding outcomes, values, and behaviors. Accountability helps build trust within a team or organization, with stakeholders and customers, and sets a precedent for others to follow.\n\n'
  },
  'achiever': {
    'name': 'Achiever',
    'description': 'In a leadership context, an \'Achiever\' is characterized by a deep-seated drive to exceed established standards and raise the bar of performance. This type of leader is distinguished by a goal-oriented approach, meticulously aligning actions with specific, quantifiable, and often challenging objectives. Achievers exhibit a blend of initiative, endurance, and strong self-confidence. They set high expectations not only for themselves but also for their teams, fostering a culture focused on high performance. At the core of an Achiever\u2019s approach is a results-focused mentality, where strategies are constantly evaluated against actual outcomes, ensuring alignment of actions with desired results.'
  },
  'adaptive': {
    'name': 'Adaptive',
    'description': 'Adaptive leadership refers to a leader\'s ability to navigate effectively through complex and changing environments. These leaders excel in tailoring their approaches to diverse situations, employing flexibility and agility in their strategies. They are proactive, data-driven, and unbiased in decision-making, fostering precision and trust. Resilient and positive, adaptive leaders maintain focus under adversity and inspire their teams to persevere. This quality is about recalibrating strategies and perspectives to meet fluctuating needs, distilling clarity from complexity, and thriving in uncertain circumstances. An adaptive leader is able to identify and capitalize on new opportunities, which can lead to business growth, increased efficiency and overall positive results.                             '
  },
  'attuned': {
    'name': 'Attuned',
    'description': 'Being \'attuned\' signifies a leader\'s adeptness in discerning and resonating with the emotional and intrinsic needs of their team. It underscores the ability to acutely perceive, understand, and respond to the emotional nuances of others, adjusting one\'s emotional responses to align harmoniously with the emotions of those around them. While intertwined with empathy\u2014the faculty to comprehend and echo another\'s feelings\u2014attunement delves deeper, emphasizing synchronization with another\'s emotional landscape. It goes beyond just recognizing emotions to actively harmonizing with them, facilitating enhanced trust, communication, and mutual understanding. This heightened emotional perception is complemented by a responsive adaptability. An attuned leader doesn\'t merely identify emotions; they skillfully adjust their reactions based on the emotional feedback of their environment, showcasing emotional agility. \n\n\n\n'
  },
  'compassionate': {
    'name': 'Compassionate',
    'description': 'Compassionate leadership is characterized by a deep commitment to understanding and addressing the diverse needs of team members, with a particular focus on forgiveness, empowerment, and a human-centered approach. This quality of leadership encompasses a spirit of leniency and understanding, allowing leaders to forgive mistakes and encourage growth. This approach not only enhances individual well-being but also fosters a sense of collective strength and resilience. This leadership quality is instrumental in building trust, loyalty, and a psychologically safe work culture, ultimately leading to more effective and human-centered organizational outcomes.'
  },
  'conflict_resolutor': {
    'name': 'Conflict resolutor',
    'description': 'Conflict Resolutor\' in leadership encapsulates the astute capability to discern, navigate, and manage internal and interpersonal divergences, be they rooted in opposing interests, emotional intricacies, unconscious biases, or cognitive misalignments. Conflict is an inherent facet of human interaction, intensified by the myriad conscious and subconscious dimensions each individual brings to the table. While the essence of conflict resolution lies in architecting peaceful, constructive solutions, it equally values the sagacity to recognize and embrace ambiguity. Not all conflicts demand clear-cut resolutions; sometimes, the wisdom lies in coexisting with the discord, understanding it, and channeling it constructively.'
  },
  'continuous_learner': {
    'name': 'Continuous learner',
    'description': 'In leadership, a "Continuous Learner" is defined by an unending curiosity and a drive to consistently expand their knowledge. These leaders view the ever-evolving business and societal landscapes as opportunities for growth, rather than challenges. Embracing change, they actively seek professional development through diverse avenues, from formal education to self-directed exploration. Their dedication to learning is enhanced by an openness to feedback, recognizing its value in personal growth. Beyond personal enrichment, they champion a culture of knowledge-sharing, ensuring that learning becomes a collaborative endeavor. '
  },
  'courageously_authentic': {
    'name': 'Courageously Authentic',
    'description': 'Authenticity\' stands as a beacon of genuine self-representation and unwavering consistency in action and thought.Courageously Authentic leaders embody a harmonious alignment between their beliefs, values, and actions, ensuring they don\'t don a facade or emulate attributes misaligned with their true selves. This raw genuineness, punctuated by transparency and an innate truth to oneself, fortifies the integrity and credibility of a leader. Beyond just being recognized for their expertise and competence, authentic leaders are perceived as pillars of trustworthiness, grounded by their consistent behavior and steadfast integrity. '
  },
  'decisive': {
    'name': 'Decisive',
    'description': 'Decisive leadership is defined by the ability to make informed, confident, and deliberate decisions quickly, even when faced with uncertainty or limited information. These leaders don\u2019t rely on snap judgments; instead, their decisions are the result of careful evaluation of the options, possible outcomes, and rational underpinnings. They have the skill to filter through large amounts of information, incorporate different viewpoints, and make choices that are both ethically sound and strategically aligned with the organization\u2019s objectives. At its heart, decisiveness is about making timely and accountable decisions that propel the organization forward, regardless of the results.'
  },
  'digital_business_acumen': {
    'name': 'Digital business acumen',
    'description': 'Digital Business Acumen\' in leadership extends beyond mere technological proficiency; it represents a profound and holistic understanding of the interplay between digital advancements and the multifaceted realms of business, society, and individual stakeholders. Leaders equipped with this acumen possess an innate ability to discern the overarching implications of digitization, weaving them into the broader organizational narrative. Their approach is not just reactive but forward-looking, fostering proactive strategies that leverage digital potential to its fullest. Such leaders are adept at integrating digital paradigms into the very fabric of the organization, ensuring adaptability amid rapid technological shifts. '
  },
  'empowering': {
    'name': 'Empowering',
    'description': 'Empowering\' as a leadership attribute signifies the deliberate act of entrusting authority, coupled with the essential tools and resources, to well-prepared team members poised to shoulder the responsibility. Leaders who embody this trait consistently look beyond mere task delegation; they foster an environment where team members are not only allowed but encouraged to assertively make decisions and exercise initiative. Such leadership stems from a deep-seated trust in team competencies, coupled with a vision to stimulate autonomy, thereby catalyzing both individual and collective growth trajectories. Empowering leadership shuns the shadows of micromanagement, instead spotlighting guidance, mentorship, and constructive feedback as its modus operandi. \n'
  },
  'engaging': {
    'name': 'Engaging',
    'description': 'The \'Engaging\' quality of leadership describes leaders who are actively involved in their organization\'s activities and rise to the occasion with solutions, particularly when faced with ambiguity. These leaders not only motivate and focus their teams on the main objectives, but they also offer strong support, guidance, and a personal touch. By truly recognizing the strengths and ambitions of each team member, they create meaningful relationships that go beyond the workplace. Their charisma and enthusiastic energy set a compelling example, inspiring teams to mirror their dedication.\n\n\n\n\n'
  },
  'entrepreneurial': {
    'name': 'Entrepreneurial',
    'description': 'Entrepreneurial\' in a leadership context encapsulates a leader\'s propensity to venture into uncharted terrains, embodying a blend of audacity, innovation, and calculated risk-taking. Such leaders not only break free from conventional moorings but also inspire their teams to tread similar adventurous paths. An entrepreneurial leader is marked by an unwavering spirit of innovation, a readiness to experiment with novel paradigms, and an acceptance of potential failures as stepping stones to eventual success. Beyond just challenging established norms, they possess a fervent drive to add value, be it through pioneering products, exploring untapped markets, or streamlining organizational processes. '
  },
  'grounded_optimism': {
    'name': 'Grounded optimism',
    'description': 'Optimistic realist\' as a leadership trait defines those leaders who adeptly fuse a hopeful outlook with pragmatic reasoning. Such leaders, while inherently optimistic about the prospects of their endeavors and the potential contained within challenges, remain anchored in empirical reality. Their decisions, though tinted with positivity, are substantiated by evidence, ensuring a balance between aspiration and feasibility. The hallmark of an optimistic realist is their duality: they harness optimism to inspire and mobilize their teams, while their realist lens facilitates informed decision-making and risk assessment. This juxtaposition ensures that they neither become disillusioned by overt optimism nor constrained by sheer realism, but rather chart a course that captures the best of both paradigms. In an unpredictable business environment, the optimistic realist leader stands as a beacon of balanced foresight, instilling confidence while ensuring pragmatic action.'
  },
  'implementer': {
    'name': 'Implementer',
    'description': 'An Implementer sees an organization as a connected system, where everything works together. The leader understands that success comes from the joint effort of all parts, including internal teams, external partners, and technology. The leader is skilled in making these elements work smoothly together. The leader focuses on building strong relationships and teamwork, and are quick to adapt to changes that affect the whole system. The Implementer\'s vision recognizes the complexity and interconnectivity in today\'s business world.'
  },
  'inclusive': {
    'name': 'Inclusive',
    'description': 'Inclusive leadership focuses on creating environments where all stakeholders, regardless of their background or characteristics, feel valued and respected. Leaders actively engage with diversity, promote equity, and work towards inclusion in every aspect of the organization. They are aware of unconscious biases and strive to make decisions that reflect diverse viewpoints. Inclusive leaders work to remove barriers to participation, especially for underrepresented groups, and emphasize the importance of cultural intelligence in a globalized world. '
  },
  'innovative': {
    'name': 'Innovative',
    'description': 'Innovative leadership involves a constant push to break new ground, encouraging creative problem-solving and new ideas. These leaders are open-minded and unafraid to take risks, seeing them as opportunities for growth. They are skilled at spotting and adapting to emerging market trends. This type of leader promotes thinking outside the box, values experimentation, and views failures as learning opportunities. They balance trying new things with building on what they already do well, focusing on managing failures rather than avoiding them.'
  },
  'machine_human_interface_and_relationship_manager': {
    'name': 'Machine-Human interface and relationship manager',
    'description': 'A "Human-Machine Interface and Relationship Manager" leader skillfully balances human skills and machine intelligence. The leader understands both human psychology and machine capabilities, ensuring they work together effectively. This leader creates an environment where technology enhances human potential, focusing on areas where machines and human creativity complement each other. They facilitate communication between tech and non-tech team members and ease concerns about technology use, ensuring a smooth adoption process. Ethical use of technology is a priority, aiming to improve worker well-being and job satisfaction. This leader also encourages continuous learning, preparing their teams to use technology effectively while maintaining human values.\n\n\n\n\n\n\n'
  },
  'networker': {
    'name': 'Networker',
    'description': 'Networker involves building and sustaining strong relationships both inside and outside the organization. Leaders with this skill use their high emotional intelligence to develop meaningful connections. They are excellent communicators and work collaboratively, uniting different people and groups towards shared goals. This quality goes beyond basic networking; it\'s about creating genuine, lasting relationships that help achieve organizational objectives. These leaders strategically use these connections to gather resources and opportunities, effectively navigating the complex dynamics of modern business.'
  },
  'people_grower': {
    'name': 'People-grower',
    'description': 'A People-grower is a leader dedicated to developing their team\'s potential. They identify and enhance individual talents through mentorship, tailored training, and growth opportunities. They go beyond assigning tasks; it\'s about creating an environment where team members feel valued and are constantly developing, both professionally and personally. People-grower leaders boost the collective strength and adaptability of their organizations by elevating each member\'s growth. This leadership style involves more than task delegation; it\'s about mentorship, providing tailored training, and creating opportunities for both professional and personal growth.'
  },
  'persuasive': {
    'name': 'Persuasive',
    'description': 'Persuasive in leadership means being able to convince others to agree with or follow your ideas, using logical reasons, emotional connections, and clear communication. It\'s about understanding what motivates others and finding a common ground to share a vision or argument that they can relate to. This quality is not about manipulating but inspiring and guiding others towards a common goal with enthusiasm.'
  },
  'self_aware': {
    'name': 'Self-aware',
    'description': 'Self-awareness in leadership is about deeply understanding one\'s strengths, weaknesses, emotions, and behaviors. Self-aware leaders are introspective and flexible, adjusting their leadership style to fit different situations. They are adept at handling stress and creating an environment open to feedback.'
  },
  'strategic_ecosystem_creator': {
    'name': 'Strategic Ecosystem creator',
    'description': 'A Strategic Ecosystem Creator is a leader skilled in forming networks of stakeholders, technologies, processes, and business models to achieve common goals and enhance collective value. The leader has a deep understanding of the industry, technological trends, and customer needs. These leader anticipates industry changes, keeping the orgnaization resilient. The leader breaks down silos, encouraging collaboration and co-creation, and strategically integrate resources to boost their ecosystem\'s capabilities, thereby maintaining a competitive edge. They focus on trust, transparency, and a shared vision, fostering an environment of co-evolution, learning, and shared success.'
  },
  'strategic_mindset': {
    'name': 'Strategic mindset',
    'description': 'In leadership, the "Strategic mindset" quality means having the ability to think holistically and plan systematically for the future. Strategic leaders understand both the detailed and big-picture factors affecting their organization. They make decisions aligned with their organization\'s vision and mission, foreseeing challenges and seizing opportunities. Their decisions balance immediate needs with long-term goals. These leaders analyze internal and external factors to create actionable plans that align with organizational values and objectives.'
  },
  'sustainability_awareness': {
    'name': 'Sustainability awareness',
    'description': 'Sustainability-awareness leadership focuses on business practices that support environmental conservation, social equity, and strong governance, known as ESG considerations. These leaders prioritize long-term well-being over short-term profits, recognizing the link between their organization\'s success and the health of the broader ecosystem. They understand their responsibility in preserving life and advocate for actions that protect the planet\'s resources, promote social justice, and maintain ethical standards.'
  },
  'tech_savvy': {
    'name': 'Tech-savvy',
    'description': 'In leadership, being "Tech-savvy" means having a deep understanding and skill in using evolving technologies. Tech-savvy leaders not only know about current technologies but also how to apply them to meet organizational goals. They use technology strategically to increase efficiency, drive innovation, and gain a competitive edge. These leaders are skilled in choosing and integrating tech tools that align with business objectives and can effectively work with IT professionals to ensure technology aligns with the organization\'s vision. They keep up with emerging tech trends, enabling them to address challenges, seize opportunities, and understand the impact of tech changes on their workforce, clients, and industry. In today\'s digital business world, this quality is crucial for organizations to succeed and navigate the complex relationship between technology and business.'
  },
  'tech_security_conscious': {
    'name': 'Tech-security conscious',
    'description': 'Tech-Security Conscientiousness refers to a leader\'s proactive approach to ensuring the safety and integrity of technology and data within an organization. This includes staying informed about the latest cybersecurity threats, advocating for robust security practices, and fostering a culture of digital vigilance among team members.'
  },
  'visionary': {
    'name': 'Visionary',
    'description': 'Visionary leaders are able to see future potentials and trends and set a compelling direction for the organization. These leaders have a clear, long-term view, often spanning a decade or more, driven by a deep passion for their goals. Visionary leaders are skilled at spotting early trends, anticipating challenges, and capitalizing on opportunities ahead of others. Their strength lies in articulating their vision in a way that inspires enthusiasm and action in their teams. They have a broad perspective, balancing immediate needs with long-term goals, and motivate their teams to think boldly and strive for excellence.'
  }
}


export interface SummaryDef {
  name: string
  description: string
}

export type SummaryKey = keyof typeof summaries

export const summaries: Record<string, SummaryDef> = {
  'summary': {
    'name': 'Summary',
    'description': 'A clear and factual summary that captures the key stories and events discussed during the interview. Avoid metaphors, figurative language, or subjective interpretations.'
  },
  'interviewee_perspective': {
    'name': 'Interviewee Perspective',
    'description': 'A detailed recount from the interviewee\'s viewpoint, focusing on their personal experiences and emotions during the events discussed.'
  },
  'contextual_perspective': {
    'name': 'Contextual Perspective',
    'description': 'An account of the events discussed during the interview from the perspectives of other participants, providing a broader context.'
  },
  'coach_perspective': {
    'name': 'Coach Perspective',
    'description': 'The coach\'s interpretation and perspective on the interview, focusing on their observations and the coaching techniques applied.'
  }
}

import type {FirebaseContextProviderProps} from './FirebaseAppContext'
import {FirebaseAppContextProvider} from './FirebaseAppContext'
import {FirebaseAuthContextProvider} from './FirebaseAuthContext'
import {FirebaseAnalyticsContextProvider} from './FirebaseAnalyticsContext'

export function FirebaseContextProvider({config, children}: FirebaseContextProviderProps) {
  return <FirebaseAppContextProvider config={config}>
    <FirebaseAuthContextProvider>
      <FirebaseAnalyticsContextProvider>
        {children}
      </FirebaseAnalyticsContextProvider>
    </FirebaseAuthContextProvider>
  </FirebaseAppContextProvider>
}

import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { CreateCoachingForm } from './CoachingForm'
import React, { useCallback, useState } from 'react'
import { BottomFab } from './BottomFab'
import { Fab } from '@mui/material'
import { Trans } from 'react-i18next'

export function NewCoachingFab() {

  const [displayed, setDisplayed] = useState(false)

  const show = useCallback(() => setDisplayed(true), [])
  const hide = useCallback(() => setDisplayed(false), [])

  return <>
    <BottomFab variant="extended" onClick={show} color="warning">
      <PersonAddIcon /><Trans i18nKey="dashboard.coachings.new-coaching" />
    </BottomFab>
    {displayed && <CreateCoachingForm displayed={displayed} hide={hide} />}
  </>
}

export function FirstCoachingFab() {
  const [displayed, setDisplayed] = useState(false)

  const show = useCallback(() => setDisplayed(true), [])
  const hide = useCallback(() => setDisplayed(false), [])

  return <>
    <Fab size="large" onClick={show} color="warning" variant="extended">
      <PersonAddIcon sx={{ marginRight: '8px' }} /> <Trans i18nKey="dashboard.coachings.create-one" />
    </Fab>
    {displayed && <CreateCoachingForm displayed={displayed} hide={hide} />}
  </>
}

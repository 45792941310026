import {Container, styled} from '@mui/material'

import MaxLogo from '../assets/logo-dark.svg'

const WelcomeContainer = styled(Container, {
  name: 'WelcomeContainer',
  slot: 'Root',
})(
  ({theme}) => ({
    backgroundColor: theme.palette.background.default,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })
)

export function LoadingPage() {
  return <WelcomeContainer disableGutters={true} maxWidth={false}>
    <MaxLogo/>
  </WelcomeContainer>
}

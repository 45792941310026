import {Breadcrumbs,  HomeBreadcrumb, PageContainer} from '@zel-labs/shared/mui'
import {useParams} from 'react-router-dom'
import { useCoaching } from './useCoaching'
import { CoachingContextProvider } from './CoachingContext'
import { CoachingBreadcrumb } from './CoachingBreadcrumb'
import { CoachingDetails } from './CoachingDetails'


export function CoachingPage() {
  const {coachingId} = useParams<{ coachingId: string }>()
  const {coachingSnapshot, role, coachingTitle} = useCoaching(coachingId)

  if (role == null) {
    return null
  }

  return <PageContainer title={coachingTitle ?? 'MAX'}>
    <CoachingContextProvider coaching={coachingSnapshot} role={role}>
      <Breadcrumbs separator="›">
        <HomeBreadcrumb/>
        <CoachingBreadcrumb/>
      </Breadcrumbs>
      <CoachingDetails/>
    </CoachingContextProvider>
  </PageContainer>
}

import {useCoachingContext} from './CoachingContext'
import {Link, Skeleton, Typography} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import { Routing } from '@zel-labs/routing'

export function CoachingBreadcrumb() {
  const {coaching, coachingTitle} = useCoachingContext()
  if (coaching == null) {
    return null
  }
  return <Link component={RouterLink} to={Routing.coaching(coaching.ref)}><Typography
    variant="body2">{coachingTitle ?? <Skeleton/>}</Typography></Link>
}

import { isAgentMessage, isUserMessage, useChatContext } from '../model'
import React, {useEffect, useMemo, useRef} from 'react'
import type { DocumentSnapshot } from 'firebase/firestore'
import { Alert, Avatar, Box, Button, Stack, styled, Typography } from '@mui/material'
import type { MessageTree, AgentMessage, ChatMessage, UserMessage } from '../model'

import PersonIcon from '@mui/icons-material/Person'
import ComputerIcon from '@mui/icons-material/Computer'
import { MessageMarkdownComponent } from './MarkdownMessageComponent'
import {useFirebaseAuth} from '../../firebase'

interface TreeViewProps {
  tree: MessageTree,
  activeChild: MessageTree | null
}

export function ThreadView({ tree, activeChild }: TreeViewProps) {
  const chatContext = useChatContext()
  const { selectThread } = chatContext
  const { message, children } = tree

  return <>
    <ChatMessageView message={message} />
    {
      children.length > 1 && <Stack direction="row" spacing={2} sx={{ pl: 4 }}>{children.map(
        (c, i) =>
          <Button disabled={c===activeChild} variant="text" key={c.message.id}
                  onClick={() => selectThread(c)} color="primary">{i + 1}</Button>
      )}</Stack>
    }
  </>
}


function ChatMessageView({ message }: { message: DocumentSnapshot<ChatMessage> }) {
  const messageData = useMemo(() => message.data(), [message])
  if (messageData===undefined) {
    throw new Error('Message data is undefined')
  }
  if (isUserMessage(messageData)) {
    return <UserMessageView message={messageData} />
  } else if (isAgentMessage(messageData)) {
    return <AgentMessageView message={messageData} />
  } else {
    throw new Error('Unknown message type')
  }
}

function UserMessageView({ message }: { message: UserMessage }) {
  const { user } = useFirebaseAuth()
  const { chat } = useChatContext()

  if (chat===undefined) {
    throw new Error('Chat is undefined')
  }

  const userTitle = useMemo(
    () => message.uid===user?.uid ? 'You':'User',
    [user, message]
  )
  return <MessageContainer>
    <Stack direction="row" alignItems="center" spacing={1}>
      <SenderAvatar className="human"><PersonIcon /></SenderAvatar>
      <Typography variant="body1" component="h3" fontWeight="bold">{userTitle}</Typography>
    </Stack>
    <MessageContentContainer>
      <MessageContent>{message.content}</MessageContent>
    </MessageContentContainer>
  </MessageContainer>
}

function AgentMessageView({ message }: { message: AgentMessage }) {
  return <MessageContainer>
    <Stack direction="row" alignItems="center" spacing={1}>
      <SenderAvatar className="agent" alt="user"><ComputerIcon /></SenderAvatar>
      <Typography variant="body1" component="h3" fontWeight="bold">AI</Typography>
    </Stack>
    <MessageContentContainer>
      <AIMessageContent message={message} />
    </MessageContentContainer>
    {message.status === 'generating' && <AutoScrollToBottom message={message}/>}
  </MessageContainer>
}

function AutoScrollToBottom({message}: {message: AgentMessage}) {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (message.status === 'generating') {
      ref.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [message])

  return <div ref={ref} style={{flexShrink: 0}}></div>
}


function AIMessageContent({message}: { message: AgentMessage }) {
  switch (message.status) {
    case 'pending':
      return <Pending />
    case 'generating':
      if (message.content==='') {
        return <Pending />
      }
      return <MessageContent>{message.content}</MessageContent>
    case 'completed':
      return <MessageContent>{message.content}</MessageContent>
    case 'failed':
      return <Alert severity="error">
        An error occurred while generating this message. Please try again.
      </Alert>
  }
}

function MessageContent({children}: { children: string | null | undefined }) {
  const {ContentComponent} = useChatContext()

  if (ContentComponent===undefined) {
    return <MessageMarkdownComponent>{children}</MessageMarkdownComponent>
  }
  return <ContentComponent>{children}</ContentComponent>
}


const SenderAvatar = styled(Avatar, { name: 'SenderAvatar', slot: 'Root' })(
  ({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&.human': {
      backgroundColor: theme.palette.primary.main
    },
    '&.agent': {
      backgroundColor: theme.palette.warning.main
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    }
  }))


const MessageContainer = styled(Box, { name: 'MessageContainer', slot: 'Root' })(({ theme }) => ({
  // backgroundColor: theme.palette.grey[100],
  // border: `1px solid ${theme.palette.divider}`,
  //padding: theme.spacing(2)
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}))

const MessageContentContainer = styled(Box, { name: 'MessageTextContainer', slot: 'Root' })(({ theme }) => ({
  paddingLeft: theme.spacing(4)
}))


function Pending() {
  const { duration, begin } = useMemo(
    () => {
      const dur = 1
      return {
        duration: `${dur}s`,
        begin: [
          `0;dot2.end-${dur / 3}s`,
          `dot0.end-${dur * 0.8}s`,
          `dot0.end-${dur * 0.6}s`
        ]

      }
    }, []
  )

  return <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24">
    <g fill="currentColor">
      <circle cx={4} cy={12} r={3}>
        <animate id="dot0" dur={duration}
                 attributeName="r" values="3;.2;3"
                 begin={begin[0]} />
      </circle>
      <circle cx={12} cy={12} r={3}>
        <animate dur={duration}
                 attributeName="r" values="3;.2;3"
                 begin={begin[1]} />
      </circle>
      <circle cx={20} cy={12} r={3}>
        <animate id="dot2" dur={duration}
                 attributeName="r" values="3;.2;3"
                 begin={begin[2]}
        />
      </circle>
    </g>
  </svg>
}

import {Container, styled} from '@mui/material'

export const CenteredContainer = styled(Container)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  gap: theme.spacing(2)
}))

import {useMeetingContext} from './MeetingContext'
import {Link, Skeleton, Typography} from '@mui/material'
import {Routing} from '@zel-labs/routing'
import {Link as RouterLink} from 'react-router-dom'

export function MeetingBreadcrumb() {
  const {meeting, data} = useMeetingContext()
  if (meeting == null) {
    return null
  }
  return <Link component={RouterLink} to={Routing.meeting(meeting.ref)}>
    <Typography
      variant="body2">{data?.title ?? data?.recordingFileName ?? <Skeleton/>}
    </Typography>
  </Link>
}

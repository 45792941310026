import { FirebaseError } from 'firebase/app'
import { AuthErrorCodes } from 'firebase/auth'


export function errorMessage(e: unknown) {
  const regex = /Firebase: (.*) \(auth\/.*\)/
  const internalErrorRegex = /Firebase: HTTP Cloud Function returned an error: (.*)/
  if (e instanceof FirebaseError) {
    const match = e.message.match(regex)
    const internalMatch = e.message.match(internalErrorRegex)
    if (match && match[1]!=='Error') {
      return match[1]
    } else if (e.code in errorMessages) {
      return errorMessages[e.code]
    } else if (e.code==='auth/internal-error' && internalMatch) {
      let data
      try {
        data = JSON.parse(internalMatch[1])
      } catch {
        return 'An unknown error occurred'
      }
      if (data.error && data.error.message) {
        return data.error.message
      } else {
        return 'An unknown error occurred'
      }
    }
  }
  return 'An unknown error occurred'
}


export const errorMessages: Record<string, string> = {
  [AuthErrorCodes.INVALID_LOGIN_CREDENTIALS]: 'Incorrect email or password',
  [AuthErrorCodes.INVALID_EMAIL]: 'Incorrect email',
  [AuthErrorCodes.EMAIL_EXISTS]: 'Email already in use',
  'auth/missing-password': 'Missing password',
  [AuthErrorCodes.NEED_CONFIRMATION]: 'Account exists with different credential'
}


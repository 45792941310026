import {useChatContext} from '../model'
import type {FormEvent} from 'react'
import { useCallback, useEffect, useState} from 'react'
import {FormControl, IconButton, InputAdornment, InputBase, styled} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

export function PromptForm() {
  const {submitPrompt, submitState, chat} = useChatContext()
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    if (submitState === 'posting') {
      setMessage('')
    }
  }, [submitState])

  useEffect(() => {
    setMessage('')
  }, [chat])

  const sendMessage = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      submitPrompt?.(message)
    },
    [submitPrompt, message]
  )

  if (submitPrompt == null) {
    return null
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      sendMessage(event)
    }
  }

  return <form onSubmit={sendMessage}>
    <FormControl fullWidth sx={{m: 1}} variant="filled">
      <PromptInput placeholder="Your message" id="user_message" multiline maxRows={4} minRows={1}
                   value={message}
                   onKeyDown={handleKeyDown}
                   onChange={e => setMessage(e.target.value)}
                   endAdornment={<InputAdornment position="end">
                     <IconButton disabled={submitState !== 'idle'} onClick={sendMessage}
                                 type="submit"
                                 color="primary"
                                 disableRipple
                                 sx={{p: 2}}><SendIcon/></IconButton>
                   </InputAdornment>}/>
    </FormControl>
  </form>
}

const PromptInput = styled(InputBase, {name: 'PromptInput', slot: 'Root'})(
  ({theme}) => ({
    fontSize: theme.typography.body2.fontSize,
    alignItems: 'flex-end',
  })
)


import type { ReactNode } from 'react'
import React, { createContext, useContext, useState } from 'react'

interface AppContextType {
  isHeaderless: boolean;
  setHeaderless: (value: boolean) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined)

export const AppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isHeaderless, setIsHeaderless] = useState(false)

  const setHeaderless = (value: boolean) => {
    setIsHeaderless(value)
  }

  const value = {
    isHeaderless,
    setHeaderless,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}

import type {PropsWithChildren} from 'react'
import {useCallback, useEffect, useReducer} from 'react'
import {useContext} from 'react'
import {createContext} from 'react'
import type {DocumentReference, DocumentSnapshot, Query} from 'firebase/firestore'
import type {Chat} from './types'
import {useFirestoreResults} from '../../firestore'


interface ChatsContextType {
  chats: DocumentSnapshot<Chat>[] | undefined | null;
  selectedChat: DocumentReference<Chat> | undefined | null;
  selectChat: (chat: DocumentReference<Chat> | null) => void;
  createChat: () => Promise<DocumentReference<Chat>>;
}

export const ChatsContext = createContext<ChatsContextType | undefined>(undefined)

interface ChatContextProviderProps<T extends Chat = Chat> extends PropsWithChildren {
  chatsQuery: Query<T>;
  createChat: () => Promise<DocumentReference<T>>;
}


export function ChatsContextProvider<T extends Chat = Chat>(props: ChatContextProviderProps<T>) {
  const {chatsQuery, createChat, children} = props

  const queryResult = useFirestoreResults(chatsQuery, {waitForWrites: true})

  const [{chats, selectedChat}, dispatch] = useReducer(
    chatListReducer,
    {chats: queryResult, selectedChat: queryResult?.[0]?.ref}
  )

  const selectChat = useCallback(
    (chat: DocumentReference<Chat> | null) => {
      dispatch({type: 'select', chat})
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch({type: 'update', chats: queryResult})
  }, [queryResult])

  return <ChatsContext.Provider
    value={{chats, selectedChat, selectChat, createChat}}>{children}</ChatsContext.Provider>
}

export function useChatsContext() {
  const context = useContext(ChatsContext)
  if (context == null) {
    throw new Error('useChatsContext must be used within a InterviewChatsContextProvider')
  }
  return context
}


type ReduceAction =
  { type: 'select', chat: DocumentReference<Chat> | null } |
  { type: 'update', chats: DocumentSnapshot<Chat>[] | undefined | null }

type ReduceState = {
  chats: DocumentSnapshot<Chat>[] | undefined | null;
  selectedChat: DocumentReference<Chat> | undefined | null;
}

function chatListReducer(state: ReduceState, action: ReduceAction): ReduceState {
  switch (action.type) {
    case 'select':
      return {...state, selectedChat: action.chat}
    case 'update': {
      const {selectedChat} = state
      const {chats} = action
      if (chats == null) {
        return {chats, selectedChat: undefined}
      }


      const isSelectedAvailable = selectedChat != null && chats.some(
        chat => chat.ref.path === selectedChat.path
      )
      if (isSelectedAvailable) {
        return {chats, selectedChat}
      }
      return {chats, selectedChat: chats[0]?.ref ?? null}
    }
    default:
      return state
  }
}

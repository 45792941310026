import type {ComponentProps} from 'react'
import ReactMarkdown from 'react-markdown'
import {Box, styled} from '@mui/material'

export function SummaryMarkdown({children}: Pick<ComponentProps<typeof ReactMarkdown>, 'children'>) {
  return <Box sx={{px: 4}}><StyledMarkdown
  >{children}</StyledMarkdown></Box>
}

const StyledMarkdown = styled(ReactMarkdown)(({theme}) => ({
  ...theme.typography.body2
}))

import {Breadcrumbs as MuiBreadcrumbs, Link, styled} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import {Routing} from '@zel-labs/routing'

export function HomeBreadcrumb() {
  return <Link sx={{display: 'flex', alignItems: 'center'}} component={RouterLink} to={Routing.home()}><HomeIcon
    fontSize="small"/></Link>
}


export const Breadcrumbs = styled(MuiBreadcrumbs, {
  name: 'Breadcrumbs',
  slot: 'Root'
})(({theme}) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  overflow: 'hidden',
  '& .MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    '& .MuiBreadcrumbs-li': {
      flexShrink: 1,
      flexGrow: 0,
      overflow: 'hidden',
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  }
}))

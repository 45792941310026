import type { Timestamp } from 'firebase/firestore'

export interface Chat {
  created_at: Timestamp;
  updated_at: Timestamp;
  title: string | null
  created_by: string | null
}

export interface ChatMessage {
  created_at: Timestamp;
  content: string;
  type: 'human' | 'agent';
  parent: string | null
}

export interface UserMessage extends ChatMessage {
  type: 'human';
  uid: string | null
}

export function isUserMessage(message: ChatMessage): message is UserMessage {
  return message.type === 'human'
}

export interface AgentMessage extends ChatMessage {
  type: 'agent';
  status: 'pending' | 'generating' | 'completed' | 'failed';
  uid: string | null
}

export function isAgentMessage(message: ChatMessage): message is AgentMessage {
  return message.type === 'agent'

}

export interface ChatUser {
  name: string;
  email: string;
  avatar: string;
}
